const isArray =  (val: any)=> Object.prototype.toString.call(val) === '[object Array]';

const isPlainObject = (val: any) => Object.prototype.toString.call(val) === '[object Object]';

const sortObject = (jsonObject: any, sortArray: boolean = false) => {
  let sorted: any = {};

  if (isArray(jsonObject)) {
    if (sortArray) {
      sorted = jsonObject.sort();
    } else {
      sorted = jsonObject;
    }

    sorted.forEach((value: any, index: number) => {
      sorted[index] = sortObject(value, sortArray);
    });

    if (sortArray) {
      sorted = sorted.sort((a: any, b: any) => {
          a = JSON.stringify(a);
          b = JSON.stringify(b);
          return a < b ? -1 : (a > b ? 1 : 0);
      });
    }
  } else if (isPlainObject(jsonObject)) {
    sorted = {};
    Object.keys(jsonObject).sort((a: any, b: any) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    }).forEach((key) => {
      sorted[key] = sortObject(jsonObject[key], sortArray);
    });
  } else {
    sorted = jsonObject;
  }

  return sorted;
}
  
const cleanJSON = (input: string) => {
  input = input.replace(/,[ \t\r\n]+}/g, '}');
  input = input.replace(/,[ \t\r\n]+\]/g, ']');
  return input;
}
  
export const sortJson: any = (inputStr: string, sortArray: boolean = false, space: number = 2) => {
  let output, obj, result;

  if (inputStr) {
    try {
      inputStr = cleanJSON(inputStr);
      obj = JSON.parse(inputStr);
      result = sortObject(obj, sortArray);
      output = JSON.stringify(result, null, space);
    } catch (ex) {
      console.error('sortJson: Incorrect JSON object.', [], ex);
      throw ex;
    }
  }

  return output;
}  