import http from './axios';

export interface OpsLogDto {
  id?: number;
  opsActionCode?: string;
  opsActionName?: string;
  opsComment?: string;
  opsDataAfter?: string;
  opsDataBefore?: string;
  opsTargetCode?: string;
  opsTargetName?: string;
  opsTargetType?: string;
}

export default class OpsLogServices {
  private readonly list_api = '/v1/sysopslog/list';
  private readonly page_api = '/v1/sysopslog/page';
  private readonly info_api = '/v1/sysopslog/info';
  private readonly remove_api = '/v1/sysopslog/delete';

  constructor() {}

  async getList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_api, { params: params });
    return res.payload;
  }

  async getPage(params?: object):Promise<any> {
    let res: any = await http.get(this.page_api, { params: params });
    return res.payload;
  }

  async getInfo(id: string | number) {
    let res: any = await http.get(this.info_api + '/' + id);
    return res.payload;
  }

  async remove(id: string | number) {
    let res: any = await http.post(this.remove_api, [id]);
    return res.header?.code == 200;
  }
}
