

























































import DictMixin from '@/mixins/dict-mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import CodeDiff from "vue-code-diff";
import { sortJson } from '@/libs/jsonsort';

@Component({
  components: {
    CodeDiff
  }
})
export default class Detail extends mixins(DictMixin) {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() detail!: any;

  before: string = '';
  after: string = '';

  @Watch('visible')
  onVisibleChange() {
    this.before = sortJson(this.detail.opsDataBefore || '{}');
    this.after = sortJson(this.detail.opsDataAfter || '{}');
  }

  bodyStyle = { backgroundColor: '#EEE'};
}
