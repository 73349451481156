import { Vue, Component, Watch } from 'vue-property-decorator';

@Component
export default class PageMixin extends Vue {
  getList() {}
  page: number = 1;
  limit: number = 10;
  total: number = 0;
  pageSizeOptions: string[] = ['10', '20', '50', '100'];
  loading: boolean = false;

  onShowSizeChange(current: number, size: number) {
    this.limit = size;
  }

  showTotal = (total: number) => `共${total}条记录`;

  @Watch('page')
  onPageChange = () => this.getList();
  @Watch('limit')
  onLimitChange = () => this.getList();

  initPage() {
    this.page = 1;
    this.limit = 10;
  }

  filter: any = {};
  excuteFilter(filter: object) {
    this.page = 1;
    this.filter = filter;
    this.getList();
  }
  resetFilter() {
    this.filter = {};
    this.page = 1;
    this.getList();
  }
}
