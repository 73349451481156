





















import { FilterItem } from '@/interfaces/filter-items';
import { Component, Watch } from 'vue-property-decorator';
import OpsLogServices, { OpsLogDto } from '@/services/opslog';
import PageMixin from '@/mixins/page-mixin';
import { mixins } from 'vue-class-component';
import Detail from './detail-drawer.vue';
import DictMixin from '@/mixins/dict-mixin';
@Component({
  components: { Detail }
})
export default class Full extends mixins(PageMixin, DictMixin) {
  logSrv = new OpsLogServices();

  columns = [
    { title: '操作日志编码', dataIndex: 'opsLogCode' },
    { title: '操作人', dataIndex: 'createUser' },
    { title: '操作时间', dataIndex: 'createTime' },
    { title: '操作对象类型', dataIndex: 'opsTargetType', customRender: (text: string) => this.DICT_VALUE('DICT_JAVA_OBJ_TYPE', text) },
    { title: '操作对象名称', dataIndex: 'opsTargetName' },
    { title: '操作动作', dataIndex: 'opsActionName' },
    {
      title: '操作',
      dataIndex: 'action',
      width: 120,
      scopedSlots: {
        customRender: 'action'
      }
    }
  ];

  filterItems: FilterItem[] = [];
  initFilter() {
    this.filterItems = [
      { label: '日志编码', key: 'opsLogCode_like', type: 'input' },
      { label: '操作人', key: 'createUser_like', type: 'input' },
      { label: '操作日期', key: 'createTime', type: 'dateRange' },
      { label: '对象类型', key: 'opsTargetType_like', type: 'select', options: this.DICT_JAVA_OBJ_TYPE },
      { label: '对象编码', key: 'opsTargetCode_like', type: 'input' },
      { label: '对象名称', key: 'opsTargetName_like', type: 'input' },
      { label: '操作动作', key: 'opsActionCode_like', type: 'select', options: this.DICT_JAVA_ACTION_TYPE }
    ];
  }

  @Watch('DICT_JAVA_OBJ_TYPE')
  onDictChange() {
    this.initFilter();
  }

  created() {
    this.getList();
    this.initFilter();
  }

  logList: OpsLogDto[] = [];
  async getList() {
    this.loading = true;
    if (this.filter.createTime) {
      this.filter.createTime_ge = this.filter.createTime[0] + ' 00:00:00';
      this.filter.createTime_le = this.filter.createTime[1] + ' 23:59:59';
      delete this.filter.createTime;
    }
    let params = { ...this.filter, page: this.page, limit: this.limit };
    let res: any = await this.logSrv.getPage(params);
    this.logList = res.records;
    this.total = res.total;
    this.loading = false;
  }

  detail: any = {};
  visible: boolean = false;
  openDetail(record: any) {
    this.detail = record;
    this.visible = true;
  }
}
