import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
@Component
export default class DictMixin extends Vue {
  spinning: boolean = false;
  @State('SYS_DICT') SYS_DICT: any;

  DICT_VALUE(code: string, value: string) {
    return this.SYS_DICT[code]?.find((v: any) => v.value == value)?.label;
  }

  DICT_CODE(code: string, value: string) {
    return this.SYS_DICT[code]?.find((v: any) => v.dictItemCode == value)?.label;
  }

  DICT_NAME(code: string) {
    return (this.SYS_DICT[code] || [])[0]?.dictName;
  }

  // 字典分类
  get DICT_DICT_KIND() {
    return this.SYS_DICT['DICT_DICT_KIND'];
  }
  // 字段类型
  get DICT_FIELD_TYPE() {
    return this.SYS_DICT['DICT_FIELD_TYPE'];
  }
  // 维表类型
  get DICT_DIM_KIND() {
    return this.SYS_DICT['DICT_DIM_KIND'];
  }
  // 同步方式
  get DICT_SYNC_TYPE() {
    return this.SYS_DICT['DICT_SYNC_TYPE'];
  }
  // 主题表分区
  get DICT_SUBJECT_PARTITION() {
    return this.SYS_DICT['DICT_SUBJECT_PARTITION'];
  }
  // 主题表字段类别
  get DICT_SUB_FIELD_TYPE() {
    return this.SYS_DICT['DICT_SUB_FIELD_TYPE'];
  }
  // 计量类型
  get DICT_MEASURE_TYPE() {
    return this.SYS_DICT['DICT_MEASURE_TYPE'];
  }
  // 计量单位
  get DICT_MEASURE_UNIT() {
    return this.SYS_DICT['DICT_MEASURE_UNIT'];
  }
  // 指标数据类型
  get DICT_IND_DATA_TYPE() {
    return this.SYS_DICT['DICT_IND_DATA_TYPE'];
  }
  // 指标类型
  get DICT_IND_TYPE() {
    return this.SYS_DICT['DICT_IND_TYPE'];
  }
  // 聚合函数
  get DICT_AGG_FUNC() {
    return this.SYS_DICT['DICT_AGG_FUNC'];
  }
  // 指标比较符
  get DICT_IND_COMP() {
    return this.SYS_DICT['DICT_IND_COMP'];
  }
  // 指标运算符
  get DICT_IND_OPERATOR() {
    return this.SYS_DICT['DICT_IND_OPERATOR'];
  }
  // 指标分层
  get DICT_IND_LEVEL() {
    return this.SYS_DICT['DICT_IND_LEVEL'];
  }
  // 数值类型规则比较符
  get DICT_RULE_COMP_DATA() {
    return this.SYS_DICT['DICT_RULE_COMP_DATA'];
  }
  // 字符类型规则比较符
  get DICT_RULE_COMP_STRING() {
    return this.SYS_DICT['DICT_RULE_COMP_STRING'];
  }
  // 日期类型规则比较符
  get DICT_RULE_COMP_DATE() {
    return this.SYS_DICT['DICT_RULE_COMP_DATE'];
  }
  // 规则参数类型
  get DICT_RULE_PARAMS_TYPE() {
    return this.SYS_DICT['DICT_RULE_PARAMS_TYPE'];
  }
  // 预警等级
  get DICT_ALERT_LEVEL() {
    return this.SYS_DICT['DICT_ALERT_LEVEL'];
  }
  // 预警指定方式
  get DICT_ALERT_ASSIGN_METHOD() {
    return this.SYS_DICT['DICT_ALERT_ASSIGN_METHOD'];
  }
  // 数据期可选维度
  get DICT_DATE_TYPE() {
    return this.SYS_DICT['DICT_DATE_TYPE'];
  }
  // 参数分类
  get DICT_PARAM_KIND() {
    return this.SYS_DICT['DICT_PARAM_KIND'];
  }
  // 周期更新
  get DICT_PERIOD_UPDATE() {
    return this.SYS_DICT['DICT_PERIOD_UPDATE'];
  }
  // 指标维度
  get DICT_IND_DIM() {
    return this.SYS_DICT['DICT_IND_DIM'];
  }
  // 数据期类型
  get DICT_DATA_DATE_TYPE() {
    return this.SYS_DICT['DICT_DATA_DATE_TYPE'];
  }
  // 指标数据格式
  get DICT_IND_DATA_FORMAT() {
    return this.SYS_DICT['DICT_IND_DATA_FORMAT'];
  }
  // 重要性
  get DICT_IMPORTANT_LEVEL() {
    return this.SYS_DICT['DICT_IMPORTANT_LEVEL'];
  }
  // 易出问题率
  get DICT_RATE_OF_ISSUE() {
    return this.SYS_DICT['DICT_RATE_OF_ISSUE'];
  }
  // 业务板块
  get DICT_BUSINESS_UNIT() {
    return this.SYS_DICT['DICT_BUSINESS_UNIT'];
  }
  // 业务部门
  get DICT_BUSINESS_DEPT() {
    return this.SYS_DICT['DICT_BUSINESS_DEPT'];
  }
  // 财务监管业务部门
  get DICT_FINANCIAL_BUSINESS_DEPT() {
    return this.SYS_DICT['DICT_FINANCIAL_BUSINESS_DEPT'];
  }
  // 指标异动监控类型
  get DICT_IND_MONITOR_TYPE() {
    return this.SYS_DICT['DICT_IND_MONITOR_TYPE'];
  }
  // 指标异动监控方向
  get DICT_IND_MONITOR_DIRECTION() {
    return this.SYS_DICT['DICT_IND_MONITOR_DIRECTION'];
  }
  // 作业处理状态
  get DICT_JOB_DISPOSE_STATUS() {
    return this.SYS_DICT['DICT_JOB_DISPOSE_STATUS'];
  }
  // 作业处理类型
  get DICT_JOB_DISPOSE_TYPE() {
    return this.SYS_DICT['DICT_JOB_DISPOSE_TYPE'];
  }
  // 平台模块
  get DICT_PLATFORM_MODULE() {
    return this.SYS_DICT['DICT_PLATFORM_MODULE'];
  }
  // 授权角色
  get DICT_ROLE_PERMISSION() {
    return this.SYS_DICT['DICT_ROLE_PERMISSION'];
  }
  // 动作类型
  get DICT_ACTION_TYPE() {
    return this.SYS_DICT['DICT_ACTION_TYPE'];
  }
  // 客户旅程场景
  get DICT_JOURNEY_SCENE() {
    return this.SYS_DICT['DICT_JOURNEY_SCENE'];
  }
  // 客户旅程子场景
  get DICT_JOURNEY_SUB_SCENE() {
    return this.SYS_DICT['DICT_JOURNEY_SUB_SCENE'];
  }
  // 客户旅程环节
  get DICT_JOURNEY_SCENE_STEP() {
    return this.SYS_DICT['DICT_JOURNEY_SCENE_STEP'];
  }
  // 经办状态
  get DICT_HANDLE_STATUS() {
    return this.SYS_DICT['DICT_HANDLE_STATUS'];
  }
  // 复核状态
  get DICT_REVIEW_STATUS() {
    return this.SYS_DICT['DICT_REVIEW_STATUS'];
  }
  // 监督状态
  get DICT_SUPERVISE_STATUS() {
    return this.SYS_DICT['DICT_SUPERVISE_STATUS'];
  }
  // 审批状态
  get DICT_APPROVE_STATUS() {
    return this.SYS_DICT['DICT_APPROVE_STATUS'];
  }
  // 审批期限
  get DICT_APPROVE_DUE() {
    return this.SYS_DICT['DICT_APPROVE_DUE'];
  }
  // 邮件发送状态
  get DICT_EMAIL_SEND_STATUS() {
    return this.SYS_DICT['DICT_EMAIL_SEND_STATUS'];
  }
  // 企业微信发送状态
  get DICT_EWECHAT_SEND_STATUS() {
    return this.SYS_DICT['DICT_EWECHAT_SEND_STATUS'];
  }
  // 参数引用类型
  get DICT_PARAM_REF_TYPE() {
    return this.SYS_DICT['DICT_PARAM_REF_TYPE'];
  }
  // 文件夹类型
  get DICT_CATEGORY_TYPE() {
    return this.SYS_DICT['DICT_CATEGORY_TYPE'];
  }
  // 审批关联业务类型
  get DICT_APPROVE_REF_TYPE() {
    return this.SYS_DICT['DICT_APPROVE_REF_TYPE'];
  }
  // 一级预警默认经办期限
  get DICT_ALERT_LEVEL1_HANDLE_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL1_HANDLE_DUE'];
  }
  // 二级预警默认经办期限
  get DICT_ALERT_LEVEL2_HANDLE_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL2_HANDLE_DUE'];
  }
  // 三级预警默认经办期限
  get DICT_ALERT_LEVEL3_HANDLE_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL3_HANDLE_DUE'];
  }
  // 一级预警默认复核期限
  get DICT_ALERT_LEVEL1_REVIEW_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL1_REVIEW_DUE'];
  }
  // 二级预警默认复核期限
  get DICT_ALERT_LEVEL2_REVIEW_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL2_REVIEW_DUE'];
  }
  // 三级预警默认复核期限
  get DICT_ALERT_LEVEL3_REVIEW_DUE() {
    return this.SYS_DICT['DICT_ALERT_LEVEL3_REVIEW_DUE'];
  }
  // 作业群组
  get DICT_SCHEDULE_JOB_GROUP() {
    return this.SYS_DICT['DICT_SCHEDULE_JOB_GROUP'];
  }
  // 操作对象类型
  get DICT_JAVA_OBJ_TYPE() {
    return this.SYS_DICT['DICT_JAVA_OBJ_TYPE'];
  }
  // 共享业务指标集市
  get DICT_SOURCE_TABLES() {
    return this.SYS_DICT['DICT_SOURCE_TABLES'];
  }
  // 操作动作类型
  get DICT_JAVA_ACTION_TYPE() {
    return this.SYS_DICT['DICT_JAVA_ACTION_TYPE'];
  }
  // 分析工具类型
  get DICT_ANALYZE_TOOL_TYPE() {
    return this.SYS_DICT['DICT_ANALYZE_TOOL_TYPE'];
  }
  // 预警类型
  get DICT_ALERT_TYPE() {
    return this.SYS_DICT['DICT_ALERT_TYPE'];
  }
  // 数据源日期区分
  get DICT_DATA_SOURCE_DATE_TYPE() {
    return this.SYS_DICT['DICT_DATA_SOURCE_DATE_TYPE'];
  }
  // 作业重跑频率
  get DICT_JOB_RERUN_RATE() {
    return this.SYS_DICT['DICT_JOB_RERUN_RATE'];
  }
  // 忽略类型
  get DICT_IGNORE_TYPE() {
    return this.SYS_DICT['DICT_IGNORE_TYPE'];
  }
}
